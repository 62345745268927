import {ProductCard, Section,Grid} from '~/components';
import {getImageLoadingPriority} from '~/lib/const';
const mockProducts = {
  nodes: new Array(12).fill(''),
};

export function CustomProductsGrid({
  title = 'Featured Products',
  products = mockProducts,
  count = 12,
  ...props
}) {
  return (
    <Section heading={title} {...props}>
    <Grid layout="products">
    {products.nodes.map((product,i) => (
      <ProductCard
        key={product.id}
        product={product}
        loading={getImageLoadingPriority(i)}
      />
    ))}
  </Grid></Section>
  );
}

import placeholder from "public/placeholder.webp"

export function SvgViewer({ line }) {
    const svg = line.attributes?.find((item) => item.key === "_svg")
    if (svg) {
        const formattedSvg = svg.value.replaceAll('*quote*', '"').replaceAll('*semi*', ', ');
        
        const viewBoxRegex = /<svg[^>]*\bviewBox=["'](.*?)["']/;
        const dRegex = /<path[^>]*\bd=["'](.*?)["']/;

        const viewBoxMatch = formattedSvg.match(viewBoxRegex)
        const dMatch = formattedSvg.match(dRegex)
        
        let viewBox, style, d;
        viewBoxMatch ? viewBox = viewBoxMatch[1] : null;
        dMatch ? d = dMatch[1] : null;

        return (
            <svg className="object-cover object-center w-24 h-24 border rounded md:w-28 md:h-28" width={110} height={110} viewBox={viewBox}>
                <path className='fill-primary stroke-primary'  d={d} />
            </svg>
        )
    }
    else {
        return <img src={placeholder} alt="Placeholder Image" width={110} height={110} className="object-cover object-center w-24 h-24 border rounded md:w-28 md:h-28" />
    }
}

import { Dialog, Transition } from "@headlessui/react";
import { Form } from "@remix-run/react";
import { Fragment, useState } from "react";
import { IconClose, IconSearch } from "./Icon";
import { Input } from "./Input";

export default function SearchBar({ params, isHome }) {
    let [isOpen, setIsOpen] = useState(false)
  
    function closeModal() {
      setIsOpen(false)
    }
  
    function openModal() {
      setIsOpen(true)
    }
  
    return (
      <>
        <button className="relative w-8 h-8 inset-0 flex items-center justify-center md:hidden"
            type="button"
            onClick={openModal}
        >
            <IconSearch />
        </button>
  
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>
  
            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-[600px] items-start justify-center mt-12 md:mt-24 p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95 -translate-y-4"
                  enterTo="opacity-100 scale-100 translate-y-0"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100 translate-y-0"
                  leaveTo="opacity-0 scale-95 -translate-y-4"
                >
                  <Dialog.Panel className="w-full max-w-lg flex transform overflow-hidden rounded-2xl bg-contrast p-2 text-left align-middle shadow-xl transition-all">
                    <Form
                      method="get"
                      onSubmit={closeModal}
                      action={params.locale ? `/${params.locale}/search` : '/search'}
                      className={`w-full items-center gap-2 flex ${
                          isHome
                          ? 'focus:border-contrast/20 dark:focus:border-primary/20'
                          : 'focus:border-primary/20'}
                          `}
                      >
                        <button
                          type="submit"
                          className="relative flex items-center justify-center w-8 h-8 mr-2"
                        >
                          <IconSearch className='w-full h-full' />
                        </button>
                        <Input required
                          type="search" autoComplete="off"
                          variant="search"
                          placeholder="Search"
                          name="q"
                        />
                    </Form>
                    <button
                      type="button"
                      className="self-start justify-center ml-4"
                      onClick={closeModal}
                    >
                      <IconClose className='w-4 h-4' />
                    </button>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </>
    )
  }